export const MenuData = [
    {
        title: "altworx",
        url: "#",
        cName: "nav-links"
    },
    {
        title: "historie",
        url: "#Historie",
        cName: "nav-links"
    },
    {
        title: "reference",
        url: "#Reference",
        cName: "nav-links"
    },
    {
        title: "kontakt",
        url: "#Kontakt",
        cName: "nav-links"
    },
]

export const MainData = [
    {
        popis: "Přináším komplexní pohled na váš busniness",
    },
    {
        popis: "podstatné informace okamžitě a adresně",
    },
    {
        popis: "Propojujeme důležité oblasti vaší společnosti",
    },
    {
        popis: "Máte přehled tadz a teď",
    },
]



export const HistData = [
    {   
        rok: "1991",
        text: "Vznik Altworx se datuje do roku 1991, kdy byla založena společnost OLYMPO controls s.r.o. Během několika let se tento podnik stává největší firmou v regionu. V roce 2004 je společnost majitelem Pavlem Kubínem zčásti prodána a stává se součástí americké korporace Honeywell. Její zachovalá část se přejmenovává na SALFORD s.r.o. a specializuje se na detekci bezpečnostních a provozních rizik. Dává vznik myšlence na nástroj, který dokáže v reálném čase zpracovávat data a dávat jim kontext.",
    },
    {   
        rok: "1991",
        text: "ALTWORX vychází z předpokladu, že ve velkých organizacích jsou i ty nejzákladnější informace o činnostech firmy roztříštěné mezi mnohá oddělení a uzavřené do jejich specializovaných systémů.",
    },
    {   
        rok: "2016",
        text: "Po několika pokusech takový produkt vyvinout se dostáváme do současnosti. V roce 2016 vzniká společnost ALTWORX s.r.o. Spolu s původními zaměstnanci se rozrostla o tým mladých, talentovaných programátorů a získala potřebné know-how. Nyní jsme připraveni našim klientům pomoci s nastavením řízení celé firmy na základě technologie našeho produktu ALTWORX.",
    },
    {   
        rok: "2016",
        text: "Na základě tohoto předpokladu vyvíjíme nástroj, který umožňuje sledovat děje ve firmě kombinováním datových otisků v primárních systémech. Jsme schopni zachytit a dále pracovat s incidenty, které není možné odhalit bez propojování dat v reálném čase, a také poskytnout vhled do činností firmy, které byly předtím uměle roztříštěné do mnoha datových zdrojů.",
    },
]

export const ContData = [
    {   
        name: "Kontaktní adresa",
        icon:"fa-solid fa-location-dot",
        company: "Altworx s.r.o.",
        street: "Lerchova 305/13",
        city: "602 00 Brno",
    },
    {   
        name:"Fakturační adresa",
        icon:"fa-solid fa-money-bill-1-wave",
        company: "Altworx s.r.o.",
        street: "Skryjova 1606/8",
        city: "614 00 Brno",
        ic: "IČ: 15529347",
        dic: "DIČ: CZ15529347",
    },
]


export const SliderData = [
    {   
        id:"1",
        text:"Rizika nových útoků",
    },
    {   
        id:"2",
        text:"Nová legislativa NIS2/nový ZoKB, DORA, ESG",
    },
    {   
        id:"3",
        text:"Firma jako celek - klíčové služby - hlavní hodnoty",
    },
    {
        id:"4",
        text:"Operations, lidé, dodavatelé, bezpečnost",
    },
    {
        id:"5",
        text:"Budu vyrábět i zítra?",
    },
    {
        id:"6",
        text:"Řešení jen v ICT nestačí",
    },
    {
        id:"7",
        text:"Spojujeme rozbité světy",
    },
    {
        id:"8",
        text:"Řízení tady a teď",
    },

]
