import React, { useState } from 'react';
import Resenisituacimodal from "./components/reseniSituaciModal";
import "./portfolioComponets.css";

function ReseniSituaci() {
    const [isOpen, setIsOpen] = useState(false)
    return (
                    <div className="portfolio-element1">
                        <button onClick={() => setIsOpen(true)}>
                            <div className="portfolio-element-top1">
                                <i className="fa-solid fa-arrows-split-up-and-left"></i>
                                <h2>Řešení situací tady a teď</h2>
                                <p>více...</p>
                            </div>
                        </button>
                            <Resenisituacimodal open={isOpen} onClose={() => setIsOpen(false)}>  
                                <div className="modal-text-left">   
                                    <h3>Řešení situací tady a teď</h3>
                                </div>
                                <div className="modal-text-right">
                                    <h4>Vidím „službu“ jako integrální celek a to spojením:</h4>
                                    <ul>
                                        <li>operation technologies – výroby</li>
                                        <li>ICT - informační a komunikační technologie</li>
                                        <li>cyber bezpečnost</li>
                                        <li>fyzickou bezpečnost</li>
                                        <li>dodavatelské řetězce – lidé, kteří ovlivňují</li>
                                    </ul>
                                    <p>tak jsem schopen</p>
                                    <ul>
                                        <li>rozeznat příčiny a dopady</li>
                                        <li>řešit je, když problémy vznikají a nejen řešit dopady – v médiích …</li>
                                        <li>řešit i dodavatelský řetězec a mít nad ním kontrolu</li>
                                    </ul>
                                    <p>NIS2/nový ZoKB, DORA a ESG nejsou jen o „počítačích”, ale o schopnosti poskytovat kritické služby a rozumět příčinám a dopadům.</p>
                                </div>
                            </Resenisituacimodal> 
                    </div>
    )
}



export default ReseniSituaci
