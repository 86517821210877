import React from "react";
import "../portfolioComponets.css";


function novaLegislativaModal({ open, children, onClose }) {
  if (open) document.body.style.overflow = "hidden";
  if (!open) document.body.style.overflow = "auto";
  if (!open) return null;

 

  

  return (
    <div className="portfolio-element-bottom1-wrapper">
      <div className="portfolio-element-bottom1">
        {children}
        <div className="close-button">
          <button onClick={onClose}>
            <i class="fa fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default novaLegislativaModal;
