import React, { useState } from 'react';
import Sitrealitymodal from "./components/sitRealityModal";
import "./portfolioComponets.css";

function SitReality() {
    const [isOpen, setIsOpen] = useState(false)
    return (
                    <div className="portfolio-element1">
                        <button onClick={() => setIsOpen(true)}>
                            <div className="portfolio-element-top1">
                                <i className="fa-brands fa-a"></i>
                                <h2>Síť reality Altworx</h2>
                                <p>více...</p>
                            </div>
                        </button>
                            <Sitrealitymodal open={isOpen} onClose={() => setIsOpen(false)}>  
                            <div className="modal-text-left">
                                <h3>Síť reality Altworx</h3>
                            </div>
                            <div className="modal-text-right">
                                <p>Síť Reality Altworx je dynamickým uložištěm reprezentující obraz reálného světa. Zachycuje objekty, jejich vlastnosti a vztahy mezi jednotlivými objekty tak, jak fungují v reálném světě.</p>
                                <p>Objektem v Síti Reality Altworx může být člověk, počítač, budova, vlak, turbína, ale také aplikace nebo nastalá situace (incident), funkce, proces.</p>
                                <p>Síť Reality Altworx je dynamickou sítí vyvíjející se na základě zpráv z datových zdrojů a výpočtů.</p>
                            </div>
                           </Sitrealitymodal> 
                    </div>
    )
}



export default SitReality