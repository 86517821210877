import React, { useState } from 'react';
import Stavsvetamodal from "./components/stavSvetaModal";
import "./portfolioComponets.css";

function StavSveta() {
    const [isOpen, setIsOpen] = useState(false)
    return (        
                    <div className="portfolio-element1">
                        <button onClick={() => setIsOpen(true)}>
                            <div className="portfolio-element-top1">    
                                <i className="fa-solid fa-globe"></i>
                                <h2>Nové výzvy, současný stav světa</h2>
                                <p>více...</p>
                            </div>
                        </button>
                            <Stavsvetamodal open={isOpen} onClose={() => setIsOpen(false)}> 
                                <div className="modal-text-left">
                                    <h3>Nové výzvy, současný stav světa</h3> 
                                </div>
                                <div className="modal-text-right">
                                    <h5>Výroba, ICT a oba světy bezpečnosti (cyber a fyzická) jsou prakticky od sebe vzájemně odtrženy. Každý vidí jen své:</h5>
                                        <ul>
                                            <li>Business vidí nespokojeného klienta, ale neví proč</li>
                                            <li>Operations vidí nefunkční stroje, ale nevidí kdo okolo nich je, ani fyzicky ani v „cyber světě“, který to může znehybnit také</li>
                                            <li>Fyzická bezpečnost vidí, kde to je a kdo okolo toho je, ale neví, že to nefunguje a proč</li>
                                            <li>Cyber bezpečnost vidí login platný, ale také nevidí, co je se strojem</li>
                                            <li>Každý z těchto „světů“ má svá rizika jen ze svého pohledu a nikoliv přes kritické služby, které se z definice skládají jako celek ze všech „světů“</li>
                                            <li>Důsledkem je např. neschopnost rozeznat SLA od cyber útoku, řešit dopady v průběhu&nbsp;a ovlivňovat průběh</li>
                                        </ul>
                                    <h5>Současný stav</h5>
                                    <ul>
                                        <li>jde se klasicky „odspodu“, tedy že se kupují jednotlivé „krabičky“ na úzce specializovanou část cyber světa, jejímuž výstupu v podobě IP adres nebo jiných „lomítek“ rozumí jen málokdo…</li>
                                        <li>odtrženo od Businessu – tzn. za IP adresou nelze vidět co, kdo, kde, co dalšího…</li>
                                        <li>samotná fyzická bezpečnost a kybernetická je od sebe oddělena na míle daleko…</li>
                                        <li>rizika – v XLS souborech definující „tisíce“ rizik – prakticky neuchopitelné…</li>
                                        <li>rozporuplnost a protichůdnost pohledů a potřeb manažerů vrcholového vedení a specialistů IT oddělení…</li>
                                    </ul>
                                </div>
                            </Stavsvetamodal> 
                    </div>
    )
}



export default StavSveta