import "./Home.css"
import logo from "../assets/Logo.svg"

function Home() {
    return (
    <div className="home">
        <div className="logo">
            <img src={logo} alt="logo" />
        </div>
    </div>
    
     );
}

export default Home