import "./Slider.css"
import { useState, useEffect } from "react";
import { SliderData } from "./MenuData";

function Slider () {
    const [index, setIndex] = useState(0)

    //hesla se toci stale dokola

    useEffect( () => {
        if (index < 0){
            setIndex(SliderData.length -1)
        } else if (index > SliderData.length -1){
            setIndex(0)
        }
    }, [index])

    // automaticke posouvani

    useEffect( ()=> {
        let setIntervalID = setInterval( () => {
            setIndex(index + 1)
        }, 5000)
        return () => clearInterval(setIntervalID)
    }, [index])
    
    return (
        <section className="slider">
            <div className="all-text-content">
                {SliderData.map( (oneText, oneTextIndex) =>{
                    const { text, id } = oneText

                    let mainClass = "next-slide"

                    if (oneTextIndex === index){
                        mainClass = "active-slide"
                    }

                    if (oneTextIndex === index -1 || (index === 0 && oneTextIndex === SliderData.length -1)){
                        mainClass = "last-slide"
                    }

                    return (
                        <article  key={id} className={mainClass}>
                            <h2>{text}</h2>
                        </article>
                    )
                })}
            </div>
        </section>
    )
}

export default Slider