import React, { useState } from "react";
import Novalegislativamodal from "./components/novaLegislativaModal";
import "./portfolioComponets.css";

function NovaLegislativa() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="portfolio-element1">
      <button onClick={() => setIsOpen(true)}>
        <div className="portfolio-element-top1">
          <i class="fa-solid fa-book-open"></i>
          <h2>Nová legislativa NIS2/nový ZoKB, DORA, ESG</h2>
          <p>více...</p>
        </div>
        </button>
        <Novalegislativamodal open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="modal-text-left" >
          <h3>Nová legislativa NIS2/nový ZoKB, DORA, ESG</h3>
        </div>
        <div className="modal-text-right" >
          <p>
            <strong>První princip</strong> vyžaduje vidět firmu jako celek,
            nejen informační a komunikační technologie. Jinak řečeno vidět firmu
            nebo instituci přes „kritické služby“ tvořící hlavní hodnoty&nbsp;a
            nikoliv jen ICT prostředí, které tyto služby podporuje. Je třeba
            vidět rizika zásadně omezující a ovlivňující schopnost „kritické
            služby“ provádět a jejich dopady. K rizikům neoddělitelně patří&nbsp;i
            lidé, kteří je ovlivňují – interní i externí – dodavatelské řetězce.
          </p>
          <p>
            <strong>Druhý princip</strong> vyžaduje řešit „nefunkčnosti“, tzn.
            odchylky od chtěného vztahu, když k nim dochází a nikoliv jen post
            mortem „damage control“, kam nevyhnutelně vede původní zavádění ZKB,
            GDPR apod. – tzn. do šanonu a koupením základních monitorovacích
            prostředků.
          </p>
        </div>
      </Novalegislativamodal>
    </div>
  );
}

export default NovaLegislativa;
