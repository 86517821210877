import "./Footer.css"

function Footer() {
    return (
        <div className="footer">
            <div className="footer-widgets">
                <p>Společnost Altworx s.r.o. je správcem i zpracovatelem osobních údajů ve smyslu čl. 4 odst. 7, 8 nařízení Evropského parlamentu a Rady (EU) č. 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (GDPR)</p>
                <p>Pokud se chcete dozvědět více o tom, jak Altworx zpracovává Vaše osobní údaje, obraťte se na nás přes e-mailovou adresu support@altworx.com.</p>
                <p>Pro poskytnutí bližších informací o ochraně osobních údajů kontaktujte pověřence pro ochranu osobních údajů, kterým je JUDr. Iva Kuckirová, advokátka zapsaná v ČAK pod č. 17063, se sídlem Starobrněnská 690/20, Brno, s e-mailovou adresou advokat@kklegal.cz, telefon +420 606 789 717</p>
            </div>
            <div className="footer-copyright">
                <p>Copyright © Altworx 2023, All rights reserved</p>
                <p>ALTWORX s.r.o. Skryjova 1606/8 614 00 Brno Czech republic VAT# CZ15529347</p>
            </div>
        </div>
    )
}

export default Footer