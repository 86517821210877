import "./Contact.css"

import { ContData } from "./MenuData"

function Contact () {
    return(
        <div className="contact" id="Kontakt">
            <div className="contact-left">
                        <div className="contact-left-element">
                            <div className="contact-left-element-top">
                                <i className="fa-solid fa-envelope"></i>
                                <h3>Napište nám</h3>
                            </div>
                            <p><a href="mailto:support@altworx.com">support@altworx.com</a></p>
                            <p><a href="mailto:altworx@altworx.com">altworx@altworx.com</a></p>

                        </div>
                        <div className="contact-left-element">
                            <div className="contact-left-element-top">
                                <i className="fa-solid fa-phone"></i>
                                <h3>Zavolejte nám</h3>
                            </div>
                            <p><a href="tel:+420734574020">+420 734 574 020</a></p>

                        </div>
                        <div className="contact-left-element">
                            <div className="contact-left-element-top">
                                <h3>Můžete nás sledovat na:</h3>
                                <a href="https://cz.linkedin.com/company/altworx-s-r-o" target="blank"><i className="fa-brands fa-linkedin"></i></a>
                            </div>
                        </div>
            </div>
            <div className="contact-right">
                {ContData.map((item, index)=>{
                    return(
                        <div className="contact-address">
                            <div className="contact-address-top-row">
                                <i className={item.icon}></i>
                                <h3>{item.name}</h3>
                            </div>
                            <p>{item.company}</p>
                            <p>{item.street}</p>
                            <p>{item.city}</p>
                            <p>{item.ic}</p>
                            <p>{item.dic}</p>
                        </div>
                    )
                })}
            </div>
     
        </div>
    )
}

export default Contact