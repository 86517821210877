
import { Component } from "react";
import { MenuData } from "./MenuData";
import "./Navbar.css"


class Navbar extends Component {
    state = {clicked: false};
    handleClick = () => {
        this.setState({clicked:
        !this.state.clicked})
    }

    /*
    <div className="lang-switch">
                    <a href="www.google.com">cz/en</a>
    </div>
    */
    render() {
        return(
            <nav className="NavbarItems">   
                <div className="menu-icons" onClick={this.handleClick}>
                    <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
                </div>  
                     
                <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
                    {MenuData.map((item, index)=>{
                        return(
                            <li key={index}>
                                <a href={item.url} className={item.cName} onClick={this.handleClick}>{item.title}</a>
                            </li>                         
                        )
                    })}
                </ul>
                
          
            
            </nav>
        )
    }
}

export default  Navbar




