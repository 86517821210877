import "./Reference.css";
import React from "react";



function Reference () {
    return (
        <div className="reference" id="Reference">
            <a href="https://www.cdcargo.cz/">
                <div className="cd_cargo reference_logo">
                </div>
            </a>
            <a href="https://www.o2.cz/osobni">
                <div className="o2 reference_logo">
                </div>
            </a>
            <a href="https://www.cez.cz/">
                <div className="cez reference_logo">
                </div>
            </a>
            <a href="https://www.lovochemie.cz/">
                <div className="lovochemie reference_logo">
                </div>
            </a>
        </div>
    )
}

export default Reference