import "./Altworx.css"

import Slider from "./Slider";

function Altworx() {
    return (
        <div className="altworx">
            <div className="helfer">
                <div className="moto">
                    <div className="moto-left">
                        <h1>Nové výzvy vyžadují vidět celek a řešit situace včas</h1>
                    </div>
                    <div className="moto-right">
                        <Slider/>
                    </div>
                </div>
            </div>    
        </div>
    );
}

export default Altworx