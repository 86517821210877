
import "./index.css"
import Navbar from "./components/Navbar"
import Home from "./components/Home";
import Altworx from "./components/Altworx";
import Portfolio from "./components/Portfolio";
import Historie from "./components/Historie";
import Reference from "./components/Reference";
import Contact from "./components/Contact";
import Footer from "./components/Footer";



export default function App() {
    return(
        <div className="App">
            <Navbar/>
            <Home/>
            <Altworx/>
            <Portfolio/>
            <Historie/>
            <Reference/>
            <Contact/>
            <Footer/>
        </div>
    );
}