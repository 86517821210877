import "./Portfolio.css";
import Stavsveta from "./components-modal/stavSveta";
import NovaLegislativa from "./components-modal/novaLegislativa";
import PropojeniSvetu from "./components-modal/propojeniSvetu";
import Sitreality from "./components-modal/sitReality";
import Resenisituaci from "./components-modal/reseniSituaci";



function Portfolio() {

    return (
        <div className="portfolio">
            <Stavsveta/>
            <NovaLegislativa/>
            <PropojeniSvetu/>
            <Sitreality/>
            <Resenisituaci/>
        </div>
    )
}



export default Portfolio