import React, { useState } from 'react';
import Propojenisvetumodal from "./components/stavSvetaModal";
import "./portfolioComponets.css";

function Propojenisvetu() {
    const [isOpen, setIsOpen] = useState(false)
    return (
                    <div className="portfolio-element1">
                        <button onClick={() => setIsOpen(true)}>
                            <div className="portfolio-element-top1">
                                <i className="fa-solid fa-circle-nodes"></i>
                                <h2>Propojení světů</h2>
                                <p>více...</p>
                            </div>
                        </button>
                            <Propojenisvetumodal open={isOpen} onClose={() => setIsOpen(false)}>  
                                <div className="modal-text-left">
                                    <h3>Propojení světů</h3> 
                                </div>
                                <div className="modal-text-right">
                                    <h4>V síti reality a jejich scénářů jsou spojeny světy:</h4>
                                    <ul>
                                        <li>Operations</li>
                                        <li>ICT</li>
                                        <li>Cyber bezpečnosti</li>
                                        <li>Fyzické bezpečnosti</li>
                                    </ul>
                                    <p><strong>Základem je chování klíčových aktiv</strong> (primárních aktiv) kritické služby jako celku. Jednotlivá data z operation, ICT, cyber a fyzické bezpečnosti nám poskytují výslednou informaci. To umožňuje řešit detekované odchylky od ideálního stavu, a to díky pochopení příčin. Jinými slovy je možné rozeznat dopady těchto příčin:</p>
                                    <ul>
                                        <li>Příklad: jestli je to Cyber útok, nebo špatně provedené SLA a co eventuálně vysvětlovat klientům nebo médiím případně oběma</li>
                                        <li>Pozn.: na úrovni managementu ve firmách propojujeme jednotlivé světy Operations, ICT, Cyber a Fyzické bezpečnosti tak, že naprosto intuitivně budou dostávat informace, které potřebují při svém rozhodování, aniž by se museli manažeři potkávat a museli rozumět „světům“ svých kolegů</li>
                                    </ul>
                                </div>
                            </Propojenisvetumodal> 
                    </div>
    )
}



export default Propojenisvetu