import "./Historie.css"

import { HistData } from "./MenuData"

function Historie () {
    return(
        <div className="historie" id="Historie">
            <div className="histori-left">
                <h1>Historie ALTWORX</h1>
            </div>
            <div className="historie-right">    
                {HistData.map((item, index)=>{
                    return(
                        <div className="historie-paragraph">
                            <p>{item.text}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Historie